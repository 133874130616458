<template>
    
    <b-card class="outer-card">
        <b-card class="inner-card">
            <div class="row" v-if="validLink == 1">
                <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Uspešna potrditev registracije. 
                </div>
                <div class="col-12 text-center">
                    Prijavite se in uporabljajte vaš račun.
                </div>
                <div class="col-12 text-center mt-1" style="font-size: 1.5rem; font-weight: bold;">
                    <b-button pill @click="bakcToLogin()">Nazaj k prijavi</b-button>
                </div>
            </div>

            <div v-else-if="validLink == 2">
                <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Povezava za potrditev registracije je potekla.
                </div>
                <div class="col-12 text-center">
                    Prijavite se in izberite ponovno pošlji potrditveni email.
                </div>
                <div class="col-12 text-center mt-1" style="font-size: 1.5rem; font-weight: bold;">
                    <b-button pill @click="bakcToLogin()">Nazaj k prijavi</b-button>
                </div>
            </div>

            <div v-else-if="validLink == 3">
                <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Račun je že aktiviran
                </div>
                <div class="col-12 text-center">
                    Prijavite se in uporabljajte vas račun.
                </div>
                <div class="col-12 text-center mt-1" style="font-size: 1.5rem; font-weight: bold;">
                    <b-button pill @click="bakcToLogin()">Nazaj k prijavi</b-button>
                </div>
            </div>
        </b-card>
    </b-card>

</template>

<script>
    import { BCard, BButton } from 'bootstrap-vue'
    export default {
        components:{
            BCard,
            BButton
        },
        data() {
            return {
                password: null,
                validLink: null,
                passwordConfirm: null

            }
        },
        methods: {
            bakcToLogin() {
                this.$router.push('/prijava')
            }
        },
        async mounted() {

            try {
                const res = await this.$http.get(`/api/user/v1/confirm_registration?linkId=${ this.$route.params.register_id}`)

                if (res.data && res.data.already_confirmed === true) {
                    this.validLink = 3
                } else {
                    this.validLink = 1
                }

            } catch (err) {
                this.validLink = 2
            }
        }
    }
</script>


<style scoped>
    .outer-card{
        box-shadow: none !important;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(180deg, rgba(234,243,249,1) 0%, rgba(234,243,249,1) 85%, rgba(255,255,255,1) 95%);
        min-height: 60vh !important;
    }

    .inner-card{
        box-shadow: none !important;
        margin-left: 2000px !important;
        margin-right: 2000px !important;
        border-radius: 10px !important;
    }

</style>